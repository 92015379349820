var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mx-4 py-4" }, [
    _c("div", { staticClass: "pa-4 black rounded white--text subtitle-2" }, [
      _vm._v(" " + _vm._s(_vm.data.data.mobile.app_description) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }