<template>
  <div class="mx-4 py-4">
    <div class="pa-4 black rounded white--text subtitle-2">
      {{ data.data.mobile.app_description }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      // APP 描述 (站台配置/app描述)
      downloadAppDec: state => state.websiteData.mobile.app_description,
    }),
  },
}
</script>

<style lang="scss" scoped>

</style>
